jQuery(function ($) {
	lazy_preload();
	lazy_load();

	$(window).on('scroll', function () {
		lazy_load();
	});


	// Переход на другую страницу
	$(document).on('click', "a:not([href^='#']):not([href^='tel']):not([href^='mailto']):not([target^='_blank']):not(.js-ajax-modal):not([data-fancybox])", function (e) {
		if (e.ctrlKey) {
			return true;
		}

		if ($(this).hasClass('js-no-show-loader')) {
			return true;
		}

		let href = $(this).attr('href'),
			tl = gsap.timeline();

		if (href === '#' || href === '' || href === undefined) {
			return false;
		}

		bodyLock();
		$('#preloader-progress-val').css('width', 0);

		tl.to($('.header__logo, #window-scroll-nav'), {
			x: -50,
			opacity: 0,
			duration: .3
		});

		tl.to($('.header-menu, .order-fixed-btn,.cookie-popup-wrap'), {
			x: 50,
			opacity: 0,
			duration: .3
		}, '<');

		tl.to($('.wrapper-line'), {
			width: '200%',
			opacity: 0,
			duration: 1
		}, "<+0.1");

		tl.to($('.preloader'), {y: '0', duration: .4}, '>-0.8');

		setTimeout(function () {
			document.location.href = href;
		}, 900);


		setTimeout(function () {
			finish_load();
		}, 1400);

		return false;

	});

});

var preload_counter = 0;

function lazy_preload() {
	var load = jQuery('.js-lazy-load.preload');

	if (load.length === 0) {
		gsap.to($('#preloader-progress-val'), {width: '100%'});

		finish_load();
	}

	load.each(function (key) {
			load_img($(this), true);
		}
	);
}

function lazy_load() {
	var load = jQuery('.js-lazy-load:not(.init):not(.preload)'),
		height = jQuery(window).height(),
		offset = 200,
		scroll = jQuery(window).scrollTop();

	load.each(function (key) {
			if (scroll + height + offset > jQuery(this).offset().top && (jQuery(this).css('display') !== 'none' || scroll !== 0)) {
				load_img($(this));
			}
		}
	);
}


function load_img(itm, preload) {
	var bg = '';

	if (is_mobile) {
		bg = itm.attr('data-image-mobile');

		if (!bg) {
			bg = itm.attr('data-image');
		}
	} else {
		bg = itm.attr('data-image');
	}

	itm.addClass('init');

	if (bg) {
		itm.css('background-image', 'url("' + bg + '")');
		itm.addClass('load-image');

		var bgimage = new Image();
		bgimage.src = bg;

		$(bgimage).on('load error', function () {
			on_img_load(itm, preload);
		});
	} else {
		if (is_mobile) {
			bg = itm.attr('data-image-src-mobile');
		}
		if (!bg) {
			bg = itm.attr('data-image-src');
		}

		if (bg) {
			itm.attr('src', bg);

			itm.one('load error', function () {
				on_img_load(itm, preload);
			});
		} else {
			on_img_load(itm, preload);
		}
	}
}

function on_img_load(itm, preload) {
	let percent = 0;
	itm.addClass('load-image');

	if (itm.hasClass('svg')) {
		svg_init();
	}

	if (preload) {
		preload_counter++;
		let all_count = $('.js-lazy-load.preload').length,
			tl = gsap.timeline();

		percent = 100 / all_count * preload_counter;

		gsap.to($('#preloader-progress-val'), {width: percent + '%'});

		if (all_count === preload_counter) {
			finish_load();
		}
	}
}

function finish_load() {
	let tl = gsap.timeline();
	let triangle = $('.preload-img__triangle');

	$('.preload').addClass('finish');

	tl.to(triangle, {
		scale: 1,
		duration: 1.6
	})

	tl.delay(0.9);
	tl.to($('.preloader'), {y: '-100%'});


	tl.to(triangle.eq(2), {
		rotate: '80deg',
		duration: 1.6,
	});
	tl.to(triangle.eq(1), {
		rotate: '40deg',
		duration: 1.6
	}, '<+.8')

	tl.to($('.preload-img__logo img'), {
		scale: 1,
		opacity: 1,
		y: 0
	});

	tl.to($('.preload-fade'), {
		opacity: 0
	});

	tl.fromTo($('.header-menu'), {
		y: -50,
		opacity: 0,
		scale: 0.6
	}, {
		y: 0,
		opacity: 1,
		scale: 1,
		duration: 1,
		delay: .6
	}, '<');
	tl.fromTo($('.hero-content .h1'),{
		x: -150,
		opacity: 0,
	},{
		x: 0,
		opacity: 1,
		duration: 1,
		delay: .6
	},'<');
	tl.fromTo($('.hero-news-itm,.soc-block'),{ 
		x: 150,
		opacity: 0,
	},{
		x: 0,
		opacity: 1,
		duration: .6,
		delay: .6,
		stagger: .3
	},'<');


}
